<template>
  <div>
    <v-img
        alt="Zu Selontra wechseln"
        contain
        :src="require('@/assets/HEADER_2400x720px_300dpi_DSV.png')"
        transition="scale-transition"
    />

    <v-divider class="my-10" />

    <v-container class="text-center">
      <p class="text-h2 pb-5">Starten Sie jetzt mit Selontra!</p>
      <v-btn color="primary" href="https://www.training.selontra.com/" target="_blank" large rel="noreferrer">
        <v-icon left>mdi-open-in-new</v-icon>
        JETZT TRAINING BUCHEN!
      </v-btn>
    </v-container>

    <v-container style="max-width: 60%" class="text-center">
      <p class="font-weight-bold">Produktverantwortung verpflichtet. Jeder geschulte, berufsmäßige Anwender,
        der Selontra in seinen Arbeitsalltag integrieren möchte, soll durch dieses Onlineseminar geschult
        und zertifiziert werden.</p>
    </v-container>

    <v-divider class="my-10" />

    <v-container style="max-width: 80%">
      <p class="text-h6">Was ist Selontra®?</p>
      <p>Mit Selontra® bringt BASF einen Schadnagerköder auf den Markt, der eine schnelle, effiziente und effektive
        Bekämpfung ermöglicht und dabei gänzlich auf Antikoagulanzien verzichtet. Durch den Wirkstoff
        Cholecalciferol, besser bekannt als Vitamin D3, ist Selontra® in der Lage, den Nagetierbefall bis zu dreimal
        schneller zu bekämpfen als die bislang angewendeten gerinnungshemmenden Rodentizide es können. Ratten- und
        Mäusestämme, die Resistenzen gegenüber herkömmlichen Rodentiziden zeigen, können aufgrund des neuartigen
        Wirkmechanismus erfolgreich bekämpft werden. Damit ist demnächst ein effizientes Werkzeug im
        Resistenzmanagement auf dem Markt verfügbar.</p>
    </v-container>

    <v-divider class="my-10" />

    <v-container style="max-width: 80%">
      <p class="text-h6">DSV e.V. übernimmt Schirmherrschaft und wird Produktbotschafter für Selontra®</p>
      <p>Gemeinsam Ziele erreichen! Unter diesem Motto gehen BASF und der Deutsche Schädlingsbekämpfer-Verband e.V.
        ab sofort neue Wege. Der DSV e.V. übernimmt die Schirmherrschaft für das neue Rodentizid Selontra® aus dem
        Hause BASF und wird Produktbotschafter.</p>
    </v-container>

    <v-divider class="my-10" />

    <v-container style="max-width: 80%">
      <p class="text-h6">Kontakt</p>
      <p class="font-weight-bold">COS Ohlsen Chemie- und Gerätevertrieb GmbH</p>
      <p>Kreisstraße 2<br/>
        24884 Geltorf-Esprehm</p>

      <p class="font-weight-bold">Vertreten durch:</p>
      <p>Ralf Ohlsen<br/>
        Kristin Kruse-Utermann</p>

      <p class="font-weight-bold">Kontakt:</p>
      <p>Telefon: +49 (0) 4354-98116 und 98117<br />
        Telefax: +49 (0) 4354-98118<br />
        E-Mail: <a href="mailto:info@cos-ohlsen.de" target="_blank">info@cos-ohlsen.de</a></p>
    </v-container>

    <v-container style="max-width: 80%">
      <v-btn color="primary" href="https://cos-ohlsen.de/" target="_blank" rel="noreferrer">
        <v-icon left>mdi-open-in-new</v-icon>
        Homepage öffnen
      </v-btn>
    </v-container>

    <v-divider class="my-10" />

    <v-container style="max-width: 80%">
      <v-alert type="warning">Biozidprodukte vorsicht verwenden! Vor Gebrauch stets Etikett und
        Produktinformation lesen.</v-alert>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'Home'
}
</script>
